import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import '@popperjs/core/dist/umd/popper.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import RegistryProvider from './provider';
import './src/global.scss';

export const wrapRootElement = RegistryProvider;
