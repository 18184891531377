import { COMPLETE_LOADER } from './actions';

export const loaders = (state = [], action: any) => {
  const { type, payload } = action;

  switch (type) {
    case COMPLETE_LOADER: {
      const { key } = payload;
      return state.concat(key);
    }
    default:
      return state;
  }
};
