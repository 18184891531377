exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-ccc-contact-tsx": () => import("./../../../src/pages/ccc-contact.tsx" /* webpackChunkName: "component---src-pages-ccc-contact-tsx" */),
  "component---src-pages-ccc-document-tsx": () => import("./../../../src/pages/ccc-document.tsx" /* webpackChunkName: "component---src-pages-ccc-document-tsx" */),
  "component---src-pages-cloud-cost-control-tsx": () => import("./../../../src/pages/cloud-cost-control.tsx" /* webpackChunkName: "component---src-pages-cloud-cost-control-tsx" */),
  "component---src-pages-cloud-tsx": () => import("./../../../src/pages/cloud.tsx" /* webpackChunkName: "component---src-pages-cloud-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-custom-software-tsx": () => import("./../../../src/pages/custom-software.tsx" /* webpackChunkName: "component---src-pages-custom-software-tsx" */),
  "component---src-pages-developers-tsx": () => import("./../../../src/pages/developers.tsx" /* webpackChunkName: "component---src-pages-developers-tsx" */),
  "component---src-pages-devops-tsx": () => import("./../../../src/pages/devops.tsx" /* webpackChunkName: "component---src-pages-devops-tsx" */),
  "component---src-pages-discount-tsx": () => import("./../../../src/pages/discount.tsx" /* webpackChunkName: "component---src-pages-discount-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-managed-it-landing-tsx": () => import("./../../../src/pages/managed-it-landing.tsx" /* webpackChunkName: "component---src-pages-managed-it-landing-tsx" */),
  "component---src-pages-managed-it-tsx": () => import("./../../../src/pages/managed-it.tsx" /* webpackChunkName: "component---src-pages-managed-it-tsx" */),
  "component---src-pages-managed-services-tsx": () => import("./../../../src/pages/managed-services.tsx" /* webpackChunkName: "component---src-pages-managed-services-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-pages-privacy-terms-tsx": () => import("./../../../src/pages/privacy-terms.tsx" /* webpackChunkName: "component---src-pages-privacy-terms-tsx" */),
  "component---src-pages-process-tsx": () => import("./../../../src/pages/process.tsx" /* webpackChunkName: "component---src-pages-process-tsx" */),
  "component---src-pages-solutions-tsx": () => import("./../../../src/pages/solutions.tsx" /* webpackChunkName: "component---src-pages-solutions-tsx" */),
  "component---src-pages-speedtomarket-tsx": () => import("./../../../src/pages/speedtomarket.tsx" /* webpackChunkName: "component---src-pages-speedtomarket-tsx" */),
  "component---src-pages-thank-you-discount-tsx": () => import("./../../../src/pages/thank-you-discount.tsx" /* webpackChunkName: "component---src-pages-thank-you-discount-tsx" */),
  "component---src-pages-thank-you-doc-tsx": () => import("./../../../src/pages/thank-you-doc.tsx" /* webpackChunkName: "component---src-pages-thank-you-doc-tsx" */),
  "component---src-pages-thank-you-it-tsx": () => import("./../../../src/pages/thank-you-it.tsx" /* webpackChunkName: "component---src-pages-thank-you-it-tsx" */),
  "component---src-pages-thank-you-newsletter-tsx": () => import("./../../../src/pages/thank-you-newsletter.tsx" /* webpackChunkName: "component---src-pages-thank-you-newsletter-tsx" */),
  "component---src-pages-thank-you-speed-tsx": () => import("./../../../src/pages/thank-you-speed.tsx" /* webpackChunkName: "component---src-pages-thank-you-speed-tsx" */),
  "component---src-pages-web-development-tsx": () => import("./../../../src/pages/web-development.tsx" /* webpackChunkName: "component---src-pages-web-development-tsx" */)
}

